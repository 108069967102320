import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/Reporting/icon-adv-1.png";
import iconAdv2 from "../images/Reporting/icon-adv-2.png";
import iconAdv3 from "../images/Reporting/icon-adv-3.png";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

//  markup
const ReportingPage = ({ location, data }) => {
  const scrollToForm = "#";

  const topProps = {
    layout: 2,
    title: (
      <span style={{ fontSize: "40px" }}>
        Make Better
        <br /> Decisions for Your
        <br /> Restaurant
      </span>
    ),
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="restaurant reporting"
        src="../images/Reporting/Reporting-1.png"
        // src="../images/Reporting/Reporting.png"
        className="mw-100"
      />
    ),
    imageSize: "big",
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: "All Your Data in One Place",
      description: `Stop struggling with separate reports from different places.
                Easily review sales reports across all your restaurants in one dashboard.`,
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: "Analyze Data Easily",
      description: `Quickly generate various reports and review only what you need with preset
                and customizable data filters.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Monitor Performance With One Click ",
      description: `Create reporting filters to review what matters in just seconds. Spend time on what matters.`,
    },
  ];

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/Reporting/Slash-Pizza.png"
          alt="Slash Pizza"
          className="mw-100 w-100"
        />
      ),
      text: `“If you own a restaurant and want to survive in this environment …
        you have to bring everything in one device.  It makes your life easier.
        I would definitely recommend orders.co” `,
      author: "Armond Zarookian",
      to: '/customer-stories/',
      position: "Owner of Slash Pizza",
    },
    testimonialsState.slash_pizza_large,
  ];

  const testimonialsProps = {
    layout: 2,
    items: testimonials,
    title: (
      <>
        Hear From
        <br /> Our Customers
      </>
    ),
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "text-small",
    title: "Compare Revenue Across All Channels",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Generate detailed reports across multiple ordering channels with just the
            click of a button. Remove the hassle of jumping between multiple dashboards to access
            the information you need.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="restaurant revenue"
        // src="../images/Reporting/Revenue.png"
        alt="Q-commerce"
        src="../images/Reporting/Q-commerce.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-small",
    title: "Make Data-Driven Decisions",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Start making well-informed decisions with data to back them.
            Easily review top performing items, platforms, and times of day!`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="restaurant data driven decisions"
        src="../images/Reporting/Data-Driven-Decisions.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-small",
    title: "All Sales In One Place",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Orders.co reporting allows you to collect sales data across multiple locations,
            brands, concepts, and platforms into one unified dashboard. See the big picture and make
            the hard decisions easy.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="restaurant sales data"
        src="../images/Reporting/Sales-Data.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-small",
    title: "No More Manual Data Gathering ",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Orders.co reporting suite automatically collects sales data from all integration
            platforms and stores. Stop chasing down the data you need to run your business.
            Orders.co brings it right to your fingertips!`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="3rd party integration data"
        src="../images/Reporting/Integration.png"
      />
    ),
  };

  const questions = [
    {
      question: `How does your sales reporting work?`,
      answer: `Orders.co sales reporting automatically captures all sales process through our system
                and populates reports daily. Users can review these reports from an Admin account on the
                desktop version of the Orders.co dashboard.`,
    },
    {
      question: `How many channels can I connect?`,
      answer: `As long as the platform is supported by Orders.co, you can integrate as many sales
                channels as you want! This includes hundreds of locations and virtual concepts.`,
    },
    {
      question: `How much does sales reporting cost?`,
      answer: `Orders.co sales reporting is provided to restaurant partners on any of our
                subscription plans free of charge.`,
    },
    {
      question: `What types of reports can I review?`,
      answer: `Orders.co sales reports offer a variety of filters for sales optimization,
                including, but not limited to, top selling items, sales by hour, and sales by provider.`,
    },
    {
      question: `How does the custom filtered reports work?`,
      answer: `Restaurant partners can generate reports with their desired filters and save them as
                presets. Allowing for quicker access of important information in the future. Custom filters
                can be specified down to a particular day or sales channel.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Reporting"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Features items={features} />
        <Testimonials {...testimonialsProps} />
        <Title>
          <b style={{ fontSize: "42px" }}>Reporting Made Easy</b>
        </Title>
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <ImageText {...imageTextProps4} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm}></Integrations>
          <News />
          <Faq items={questions} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "reporting" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default ReportingPage;
